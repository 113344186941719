import { Component, OnInit, ViewChild, ElementRef, NgZone, TemplateRef } from '@angular/core';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { map, catchError } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';

import { PrescriptionService } from '../../service/prescription.service';
import { PharmacyService } from '../../service/pharmacy.service';
import { UploadService } from '../../service/upload.service';
import { UserService } from 'src/app/service/user.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { MatDatepickerInputEvent, MatSnackBar } from '@angular/material';
import { CreatePatientComponent } from './create-patient/create-patient.component';
import { Pharmacy } from 'src/app/shared/models/pharmacy.model';
import { DatePipe } from '@angular/common';
import { PrescriptionType } from 'src/app/shared/pipes/prescription-status.pipe';
import { Address, Prescription } from 'src/app/shared/models/prescription.model';
import { ThrowStmt } from '@angular/compiler';
import { ConfirmationDialogComponent } from 'src/app/shared/view/confirmation-dialog/confirmation-dialog.component';
import { LocalDateTimePipe } from 'src/app/shared/pipes/local-date-time.pipe';
import { PrescriptionImagesComponent } from '../prescription-images/prescription-images.component';

declare var $: any;
declare var Compress: any;



@Component({
  selector: 'app-prescription-edit',
  templateUrl: './prescription-edit.component.html',
  styleUrls: ['./prescription-edit.component.css']
})
export class PrescriptionEditComponent implements OnInit {
  compress = new Compress();
  todayDate = new Date();
  @ViewChild('patientID', null) patientID;
  prescription: Prescription = { isDeliveryAddressNew: -1, requiredCashCollection: 0, cashCollected: 0 } as Prescription;
  pharmacies: Array<Pharmacy>;
  deliverySlots: any[] = null;
  pharmacyIds: any[] = null;
  @ViewChild("fileUpload", { static: false }) fileInput: ElementRef;
  @ViewChild('imageInput', { static: false }) imageInputRef: ElementRef;
  files = [];
  file: any;
  myGroup: FormGroup;
  sub: Subscription;
  searchQuery = "";
  fileUploadRquired: true;
  prescriptionFileInput: any[];
  imageSrc: string;
  uploadFiles: boolean;
  options: any;
  paramid: any;
  constructor(private route: ActivatedRoute, public dialog: MatDialog, private fb: FormBuilder,
    private router: Router, private datePipe: DatePipe, private _snackBar: MatSnackBar,
    private prescriptionService: PrescriptionService, private zone: NgZone,
    private pharmacyService: PharmacyService, private userService: UserService,
    private uploadService: UploadService, private localDateTime: LocalDateTimePipe) {
    this.pharmacyService.getAll().subscribe(data => {
      this.pharmacies = data;
      // // // // console.log("pharmacies=",this.pharmacies)
      this.deliverySlots = this.pharmacies.find(x => x.id == GlobalConstants.selectedPharmacyID).deliverySlots;
      this.pharmacyIds = this.pharmacies.map(item => item.id);
      //  console.log("calling pharmacy id",this.pharmacyIds)
      this.addPharmacyPatient('',this.pharmacyIds)
      // var time = parseInt(this.datePipe.transform(new Date(), "HH")!);
      // if (this.deliverySlots) {
      //   this.deliverySlots.forEach(element => {
      //     // // // console.log(element.endTime.substring(0, 2));
      //     var t = parseInt(element.startTime.substring(0, 2)) - 2;
      //     element.disable = t < time;
      //     element.tomorrow = false;
      //   });
      //   JSON.parse(JSON.stringify(this.deliverySlots)).forEach(element => {
      //     element.disable = false;
      //     element.tomorrow = true;
      //     element.startTime = "n_" + element.startTime
      //     this.deliverySlots.push(element);
      //   });

      // }
      // if (!this.deliverySlots || this.deliverySlots.length <= 0) {
      //   this.deliverySlots = [{ "startTime": "14:00:00", "endTime": "16:00:00" }, { "startTime": "16:00:00", "endTime": "18:00:00" }, { "startTime": "18:00:00", "endTime": "20:00:00" }];
      // }
    });
    this.myGroup = this.fb.group({
      id: [],
      pharmacyID: [],
      patientID: [],
      prescriptionType: [],
      prescription_custom_status:[],
      prefferdDeliveryTime: [],
      firstName: [],
      lastName: [],
      unitNo: [],
      buildingName: [],
      streetAddress: [],
      city: [],
      postalCode: [],
      noteFromPatient: [],
      delAddress: [],
      trackWithOtp: [true],
      repeatable: [true],
      repeateInDays: [''],
      searchQuery: [],
      requiredCashCollection: [false],
      cost: [false],
      userSpecificNote: [],
      prefferdDeliveryDate: [],
      isCovidSample: [false],
      fileInput: []
    });

  }
  listUsers = [];
  userSelectPharmacy: Pharmacy = null;
  ngOnInit() {
    this.uploadFiles = false;
    this.route.snapshot.params.id
    this.sub = this.route.params.subscribe(params => {
      this.paramid = params.id;
      if (this.paramid == "new") {
        var currentDate = new Date();
        // // // console.log('currentDate ='+currentDate)
        this.prescription = { deliveryAddress: { postalCode: "", } as Address, prefferdDeliveryDate: currentDate, isDeliveryAddressNew: -1, trackWithOtp: true, cashCollected: 0, requiredCashCollection: 0 } as Prescription;

        this.prescription.pharmacyID = GlobalConstants.selectedPharmacyID;
        // this.searchPharmacyPatient("");
      }
      else {
        this.prescriptionService.get(this.paramid).subscribe((pr: any) => {
          if (pr) {
            this.prescription = pr;
            // this.searchPharmacyPatient("");
            // // // console.log("prescription editable data =",this.prescription,this.prescription.prescriptionLinks)
            this.uploadFiles = true;
            if (!this.prescription.deliveryAddress) {
              this.prescription.isDeliveryAddressNew = -1;
              this.prescription.deliveryAddress = {} as Address;
            }
            this.prescription.prefferdDeliveryDate = this.localDateTime.transform(this.prescription.prefferdDeliveryDate);
            this.todayDate = this.localDateTime.transform(this.prescription.prefferdDeliveryDate);
            this.todayDate.setDate(this.todayDate.getDate() - 1);
            // if (this.localDateTime.transform(this.prescription.prefferdDeliveryDate) > new Date()) {
            //   this.prescription.prefferdDeliveryTime = "n_" + this.prescription.prefferdDeliveryTime;


            // }
            // // // console.log(this.prescription.patient)
            if(this.prescription.patient != null){
            this.prescription.patientFullName = this.prescription.patient.firstName + " " + this.prescription.patient.lastName;
            // // // console.log(`prescription with id '${this.paramid}' found` + pr);
            }
            else{
              // // // console.log(this.prescription.patient,'else')
              this.prescription.patientFullName= '';
              this.prescription.patient.firstName = '';
              this.prescription.patient.lastName = '';
              // this.searchPharmacyPatient('');
            }
            //this.giphyService.get(car.name).subscribe(url => car.giphyUrl = url);
          } else {
            // // // console.log(`prescription with id '${this.paramid}' not found, returning to list`);
            this.gotoList();
          }
        });
      }
    });


    this.pharmacyService.getAll(false).subscribe(x => {

      this.userSelectPharmacy = x.find(x => x.id == GlobalConstants.selectedPharmacyID);
      // // // console.log(this.userSelectPharmacy);
      this.options = this.userSelectPharmacy.prescription_custom_status
    });

    if (GlobalConstants.selectedPharmacyID) {
      // // // // console.log("selected pharmacy=",GlobalConstants.selectedPharmacyID);
      this.pharmacyService.getAll(false).subscribe(x => {

        this.userSelectPharmacy = x.find(x => x.id == GlobalConstants.selectedPharmacyID);
        // // // console.log("Pharmacy level on detail", 'prescription_custom_status = '+this.userSelectPharmacy.prescription_custom_status);
        this.options = this.userSelectPharmacy.prescription_custom_status
        // // // console.log("customstatus=",this.options)

      });

    }


  }


  ngOnDestroy() {
    //this.sub.unsubscribe();
  }

  gotoList() {
    this.router.navigate(['../prescription/prescription-list']);
  }

  save(form: NgForm) {
    this.prescriptionFileInput = this.input_b5;

    //  let prescriptionFileInput = this.fileUploadRquired ? this.input_b5 : [];
    // // // console.log('calling savE function >>' + this.prescriptionFileInput)
    // upload prescription file first
    if (this.prescription.cost == undefined) {
      this.prescription.cost = -1;
    }
    if (this.prescription.prescriptionType == PrescriptionType.SELF_PICKUP) {
      this.prescription.deliveryAddress = null;
    }
    else if (!this.prescription.deliveryAddress || !this.prescription.deliveryAddress.firstName || !this.prescription.deliveryAddress.lastName || !this.prescription.deliveryAddress.streetAddress || !this.prescription.deliveryAddress.city) {
      this._snackBar.open("Please enter your delivery address.", "Error", {
        duration: 3000,
      });
      return;
    }
    else if (this.prescription.requiredCashCollection > 0 && this.prescription.cost <= 0) {
      this.prescription.cost = null;
      this._snackBar.open("Please enter cost amount.", "Error", {
        duration: 3000,
      });
      return;

    }
    if (!this.prescription.prefferdDeliveryDate) {
      this._snackBar.open("Please select delivery date.", "Error", {
        duration: 3000,
      });
      return;
    }
    if ((!this.deliverySlots || this.deliverySlots.length <= 0) && !this.prescription.prefferdDeliveryTime) {
      this.prescription.prefferdDeliveryTime = this.datePipe.transform(new Date(), "HH:mm:ss")
    }
    if (!this.prescription.prefferdDeliveryTime) {
      this._snackBar.open("Please selete delivery time.", "Error", {
        duration: 3000,
      });
      return;
    }
    // if (this.prescription.prescriptionType == 1 && this.deliverySlots && this.deliverySlots.length > 0 && !this.prescription.prefferdDeliveryTime) {
    //   this._snackBar.open("Please select prefferd delivery time.", "Error", {
    //     duration: 3000,
    //   });
    //   return;
    // }
    this.prescription.createdByPharmacy = true;
    this.prescription.isDeliveryAddressNew = this.prescription.prescriptionType == 0 ? false : this.prescription.isDeliveryAddressNew == -1 && this.prescription.prescriptionType > 0;

    var currentDate = this.prescription.prefferdDeliveryDate;
    // // // console.log('currentDate = '+currentDate)
    currentDate.setHours(parseInt(this.prescription.prefferdDeliveryTime.substring(0, 2)));
    if (this.prescription.prefferdDeliveryTime && this.prescription.prefferdDeliveryTime.startsWith("n_", 0)) {
      this.prescription.prefferdDeliveryTime = this.prescription.prefferdDeliveryTime.replace("n_", "");

      currentDate.setDate(currentDate.getDate() + 1);
    }

    $('.csLoader').css({ display: 'flex' });
    this.pharmacyService.getESTCurrentTime("1").subscribe(dateEst => {
      $('.csLoader').css({ display: 'none' });
      var estDate = new Date(dateEst);
      var deliveryWillbeDelveredTommorow = this.prescription.prescriptionType > 0 && currentDate.getDate() == new Date().getDate() &&
        estDate.getHours() >= 15

      if (deliveryWillbeDelveredTommorow) {
        currentDate.setDate(currentDate.getDate() + 1);
        var msg = `This delivery will be delivered on ` + this.datePipe.transform(currentDate, "MMM-dd-yyyy") + `<br/>Do you want save prescription?.<br/><small class="danger"><b>Note: </b>Any deliveries entered after 3pm go to the next day.</small>`;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "420px",
          data: {
            isRadio: false,
            message: msg,
            buttonText: {
              ok: 'Yes',
              cancel: 'No'
            }
          }
        });

        dialogRef.afterClosed().subscribe((confirmed: any) => {

          if (confirmed != undefined && confirmed) {
            this.confirmIfAfterFourPm(currentDate, deliveryWillbeDelveredTommorow);
          }
          else {
            this._snackBar.open("Action cancelled", "OK", {
              duration: 2000,
            });
          }
        });
      }
      else {
        this.confirmIfAfterFourPm(currentDate, deliveryWillbeDelveredTommorow);
      }

    }, err => {
      $('.csLoader').css({ display: 'none' });
    });
  }

  private confirmIfAfterFourPm(currentDate: Date, deliveryWillbeDelveredTommorow: boolean) {
    this.prescription.prefferdDeliveryDate = this.datePipe.transform(new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");

    if (this.prescription.prescriptionType >= 1) {
      var pharmacyAddress = this.userSelectPharmacy.name + " " + this.userSelectPharmacy.add1 + " " + this.userSelectPharmacy.add2 + " " + this.userSelectPharmacy.pin;
      var userAddress = this.prescription.deliveryAddress.streetAddress + " " + this.prescription.deliveryAddress.city + " " + this.prescription.deliveryAddress.postalCode;
      var repeateDay = parseInt((this.prescription.repeateInDays + ""));
      if (this.prescription.repeatable && repeateDay > 0) {
        currentDate.setDate(currentDate.getDate() + repeateDay);
        this.prescription.nextDeliveryDate = this.datePipe.transform(new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
      }
      else {
        this.prescription.repeatable = false;
      }
      $('.csLoader').css({ display: 'flex' });
      this.prescriptionService.findAddressRoute(pharmacyAddress, userAddress).then(response => {

        $('.csLoader').css({ display: 'none' });
        if (response.rows && response.rows[0].elements) {
          var distance = response.rows[0].elements[0].distance.value;
          this.prescription.distance = distance;
          if (this.prescription.prescriptionType >= 2) {
            this.validateDistance(this.prescription.distance);
          }
          else {
            this.savePrescription();
          }
        }
      }).catch(x => {
        $('.csLoader').css({ display: 'none' });
        // this._snackBar.open("Delivery is no availble at this address.", "Error", {
        //   duration: 3000,
        // });
        this.confirmIfAddresNotFoudOrOutOf(0);
        return;
      });
    }
    else {
      this.savePrescription();
    }
  }
  distanceCharge = [{ min: 0, max: 5000, charge: 10 }, { min: 5000, max: 20000, charge: 15 }, { min: 20000, max: 35000, charge: 25 }];

  validateDistance(distance: number) {
    var charge = this.distanceCharge.filter(x => distance > x.min && distance <= x.max).map(x => { return x.charge });
    if (charge) {
      var km = (distance / 1000).toFixed(2);
      var confirmationText = `Express delivery will cost extra $${charge[0]} for ${km} km.`;
      this._snackBar.open(confirmationText, "Info", {
        duration: 3000,
      });
      this.savePrescription();
    }
    else {
      // this._snackBar.open("Delivery is no availble at this address.", "Error", {
      //   duration: 3000,
      // });
      this.confirmIfAddresNotFoudOrOutOf(1);
    }
  }

  private savePrescription() {
    this.userSpecificNoteChanged();

    // var formdata = new FormData();
    // formdata.append('file', this.file);
    // // // // console.log('>>>>>>>>>>>file = '+this.file)
    // this.prescription=(formdata);
    this.prescription.prescription_source = "Scan";
    // // // console.log('ALl Prescription ' + this.prescription)
    // // // console.log('>>>>>>>>>>>prescriptionFileInput= ' + this.prescriptionFileInput)
    this.prescription.prescription_reuploaded = false;
    // this.prescription.prescription_custom_status ="New Prescription"
    //formdata
    // // // console.log("pres",this.prescription.prescription_custom_status)
    this.prescriptionService.saveWithoutFile(this.prescription, this.prescriptionFileInput).subscribe(result => {
      // // // console.log("Result Prescription=", result)
      // // // console.log("check=",result.prescription_reuploaded)
      if (result.prescription_reuploaded) {

        const dialogRef = this.dialog.open(PrescriptionImagesComponent, {
          width: '400px',
          data: {
            confirmAction: this.confirmAction.bind(this),
            cancelAction: this.cancelAction.bind(this)
          }
          
        });
    
        // Handle dialog close or other events if needed
        dialogRef.afterClosed().subscribe(result => {
          // Handle dialog close event
        });
     
      }else{

        // this.prescriptionService.saveWithoutFile(this.prescription, this.prescriptionFileInput).subscribe(result => {
        //   // // // console.log("Result Prescription after proceed=", result)
        this.gotoList();
        // }, error => console.error(error));

      }

      if (result && this.paramid != 'new') {

        this._snackBar.open("Prescription updated successfully", "OK", {
          duration: 1000,

        });
        this.gotoList();
        // this.router.navigate(['../prescription/prescription-detail']);


      }
      // this.gotoList();
    }, error => console.error(error));
  }


  confirmAction(): void {
    // Perform confirm action
    // // // console.log('Confirm action executed');
    this.userSpecificNoteChanged();

    // var formdata = new FormData();
    // formdata.append('file', this.file);
    // // // // console.log('>>>>>>>>>>>file = '+this.file)
    // this.prescription=(formdata);
    this.prescription.prescription_source = "Scan";
    // // // console.log('ALl Prescription ' + this.prescription)
    // // // console.log('>>>>>>>>>>>prescriptionFileInput= ' + this.prescriptionFileInput)
    this.prescription.prescription_reuploaded = true;
    // this.prescription.prescription_custom_status ="New Prescription"
    //formdata
    // // // // console.log("pres",this.prescription.prescription_custom_status)
    this.prescriptionService.saveWithoutFile(this.prescription, this.prescriptionFileInput).subscribe(result => {
      // // // console.log("Result Prescription after proceed=", result)
      this.gotoList();
    }, error => console.error(error));
  }

  cancelAction(): void {
    // Perform cancel action
    this.prescription.prefferdDeliveryDate = this.todayDate
    // this.prescription.prefferdDeliveryTime = this.todayDate
    // // // console.log('Cancel action executed');
  }

  input_b5: any[] = [];


  onFileSelect(event: any) {
    this.uploadFiles = true;

    const reader = new FileReader();
    let id = 'input-b5';
    this.input_b5 = [];
    if (event.target.files) {
      const fileUrl = event.target.files[0];
      // // // console.log('fileUrl >> ' + fileUrl)
      this.file = fileUrl;
      // // // console.log('file >> ' + this.file)
      // // // // console.log("this.file=",this.file)

      reader.readAsDataURL(this.file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;
      };
      // this.prescriptionFileInput=fileUrl;
      // const files1 = event.target.files[0];
      // this.myGroup.get('fileInput').setValue(files);
      console.log("files =>>>>>>>>>>>>> " + event.target.files)

      const files = [...event.target.files]
      console.log("files1 = " + files)

      var extension=".jpeg";
      files.forEach((file: File) => {
         extension = file.name.split('.').pop().toLowerCase(); // Get the file extension
        const fileName = `file_${new Date().getTime()}.${extension}`; // Generate new file name
        // Add the file to the list with the new name
        this.files.push(new File([file], fileName));
      });
      console.log("extension =>>>>>>>>>>>>> " + extension)

      // const extension = files.name.split('.').pop();// Get the file extension
      // console.log(">>>>>>>>>>>>>>>>>>>> " + extension)
      // const fileName = `file_${new Date().getTime()}.${extension}`; // Generate new file name

      this.compress.compress(files, {
        size: 4,
        quality: .75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      }).then((data: any[]) => {
        // // // console.log(data);
        var newFiles: any = [];
        data.forEach(element => {
          // newFiles.push(this.base64ToFile(element.prefix + element.data, `compressed_${new Date().getTime()}.jpeg`));
          newFiles.push(this.base64ToFile(element.prefix + element.data, new Date().getTime() +"."+extension));
        });
        if (id == "input-b5") {
          this.input_b5 = newFiles;
          // // // console.log(this.input_b5);
        }
      })
    }
  }

  base64ToFile(dataURI: any, fileName: any): File {

    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }

  remove(href) {
    this.prescriptionService.remove(href).subscribe(result => {
      this.gotoList();
    }, error => console.error(error));
  }

  //   onFileSelect(event: any) {
  //     if (event.target.files.length > 0) {
  //         // // // console.log('event.target.files.length >> ' + event.target.files.length)
  //         const fileUrl = event.target.files[0];
  //         // // // console.log('file >> ' + fileUrl)
  //         this.myGroup.get('fileInput').setValue(fileUrl);
  //         this.file = fileUrl;
  //         // this.prescriptionFileInput=fileUrl;

  //     }
  // }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.prescriptionService.upload(formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`Upload failed: ${file.data.name}`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          // // // console.log(event.body);
        }
      });
  }
  onClick() {
    /* const fileUpload = this.fileUpload.nativeElement;fileUpload.onchange = () => {
     for (let index = 0; index < fileUpload.files.length; index++)
     {
      const file = fileUpload.files[index];
      this.files.push({ data: file, inProgress: false, progress: 0});
     }
       this.uploadFiles();
     };
     fileUpload.click();*/
  }

  openNewPatientDialog() {
    const dialogRef = this.dialog.open(CreatePatientComponent, {
      width: '420px',
      // height: '1220px',
      data: { addNewOnly: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      // // // console.log('The dialog was closed');
      this.patientID.close();
      if (result) {
        if (!this.listUsers.find(x => x.id == result.id)) {
          this.listUsers.push(result);
          this.prescription.patientID = result.id;
          this.patientID.close();
        }
        else {
          this.prescription.patientID = result.id;
          this.patientID.close();
        }
        this.prescription.patientFullName = result.firstName + " " + result.lastName;
        this.PatientChane(null);
      }
    });
  }

  oldUserSpecificNote = "";
  addresses: Array<Address> | undefined;
  PatientChane(evenet) {
    this.prescription.deliveryAddress = {
      firstName: '',
      lastName: '',
      unitNo: '',
      buildingName: '',
      streetAddress: '',
      city: '',
      country: '',
      postalCode: ''
    };
    this.addresses = null;
    debugger;
    var user = this.listUsers.find(x => x.id == this.prescription.patientID);
    if (user) {
      this.prescription.patientFullName = user.firstName + " " + user.lastName;
      this.userService.getUserAddresses(user.id).subscribe(data => {
        this.addresses = data ? data.address : null;
        this.prescription.userSpecificNote = data ? data.note : "";
        this.oldUserSpecificNote = this.prescription.userSpecificNote
        this.prescription.isDeliveryAddressNew = this.addresses && this.addresses.length > 0 ? 0 : -1;
        setTimeout(() => {
          this.AddressChanged();
        }, 200);

      });
    }
  }
  AddressChanged() {
    debugger;
    if (this.prescription.isDeliveryAddressNew > -1) {
      this.prescription.deliveryAddress = this.addresses![this.prescription.isDeliveryAddressNew];
    }
    else {
      var selecteduser = this.listUsers.find(a => a.id == this.prescription.patientID);
      this.prescription.deliveryAddress = {
        firstName: selecteduser.firstName,
        lastName: selecteduser.lastName,
        unitNo: '',
        buildingName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: ''
      };
    }
  }


  confirmIfAddresNotFoudOrOutOf(distance: number = 0) {
    var msg = "We are not able to find the patient address, do you want to add distance manually?";
    if (distance > 0) {
      msg = "Patient distance is greater than 35 KM, do you want to override the distance?";
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "420px",
      data: {
        isRadio: false,
        message: msg,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {

      if (confirmed != undefined && confirmed) {
        setTimeout(() => {
          this.getUserInputForDistance();
        }, 100);
      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }
    });

  }

  getUserInputForDistance() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "420px",
      data: {
        isRadio: false,
        isInputRequired: true,
        message: 'Please enter distance in KM.',
        buttonText: {
          ok: 'Save',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {

      if (confirmed != undefined && confirmed != false) {
        var dis = 0.0;
        debugger;
        try {
          dis = parseFloat(confirmed);
        } catch (e) { }
        if (dis <= 0 || isNaN(confirmed)) {
          this._snackBar.open("Please enter a valid distance.", "Error", {
            duration: 2000,
          });
        }
        else {

          this.prescription.distance = dis * 1000;
          this.savePrescription();
        }
      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }
    });
  }
  isSearching = false;

  filterPatient() {
    // this.listUsers.map(u => {
    //   if (this.searchQuery && u.id) {
    //     u.show = (u.firstName + u.lastName + u.phoneNumber).toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1;
    //   }
    //   else {
    //     u.show = true;
    //   }
    // });
    // if (this.searchQuery.length > 3 && !this.isSearching) {
    //   this.isSearching = true;
    //   this.searchPharmacyPatient(this.searchQuery);
    // }
    if (!this.searchQuery) {
      this.isSearching = false;
    }
    if (this.searchQuery && !this.isSearching) {
      this.isSearching = true;
    }
    let wordSearch = this.searchQuery;
    setTimeout(() => {
      if (wordSearch == this.searchQuery) {
        if (this.searchQuery) {
          this.isSearching = true;
          // this.searchPharmacyPatient(this.searchQuery);
        } else {
          //code here
        }
      }
    }, 1000);
  }
  // searchPharmacyPatient(query: string) {
  //   // // // console.log('query=',query)
  //   this.listUsers = [];
  //   this.userService.getUsersByPharmacy(GlobalConstants.selectedPharmacyID, query, 0, 100).subscribe(x => {
  //     this.isSearching = false;
  //     // console.log("User=",x)
  //     if (x.count > 0) {
  //       this.listUsers.push({ id: "", firstName: "Select patient", phoneNumber: "", show: true })
  //       x.data.forEach(element => {
  //         element.show = true;
  //         this.listUsers.push(element);
  //       });
  //     }
  //     else {
  //       this.listUsers.push({ id: "", firstName: "No patient found.", phoneNumber: "", show: true })
  //     }

  //   }, err => {
  //     this.isSearching = false;
  //   });
  // }

  addPharmacyPatient(query: string, pharmacys) {
    // // // console.log('query=',query)
    this.listUsers = [];
    // this.userService.getUsersByPharmacy(GlobalConstants.selectedPharmacyID, query, 0, 100).subscribe(x => {
    console.log("Pharmacys=", pharmacys)
    this.userService.getPatientByPharmacy(pharmacys, query, 0, 100).subscribe(x => {
      this.isSearching = false;
      console.log("Patient=", x.data);
      if (x.count > 0) {
        this.listUsers.push({ id: "", firstName: "Select patient", phoneNumber: "", show: true })
        x.data.forEach(element => {
          element.show = true;
          this.listUsers.push(element);
        });
      }
      else {
        this.listUsers.push({ id: "", firstName: "No patient found.", phoneNumber: "", show: true })
      }

    }, err => {
      this.isSearching = false;
    });
  }

  addressSelected(data) {
    // // // // console.log(data);
    this.zone.run(() => {

      this.prescription.deliveryAddress.unitNo = data.unitNo;
      this.prescription.deliveryAddress.postalCode = data.postalCode;
      this.prescription.deliveryAddress.city = data.city;
      this.prescription.deliveryAddress.streetAddress = data.streetAddress;
    });
  }
  addressToDelete = -1;
  deleteAddress(i: number) {
    this.addressToDelete = i;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "420px",
      data: {
        isRadio: false,
        message: "Do you want to delete the address?",
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {

      if (confirmed != undefined && confirmed) {
        var user = this.listUsers.find(x => x.id == this.prescription.patientID);
        if (user) {
          this.prescription.patientFullName = user.firstName + " " + user.lastName;
          this.userService.deleteUserAddresses(user.id, this.addressToDelete).subscribe(data => {
            this.addresses = data ? data.address : null;

            this.prescription.isDeliveryAddressNew = this.addresses ? 0 : -1;
            setTimeout(() => {
              this.AddressChanged();
            }, 200);

          });
        }
      }
      else {
        this._snackBar.open("Action cancelled", "OK", {
          duration: 2000,
        });
      }
    });
  }
  userSpecificNoteChanged() {
    var user = this.listUsers.find(x => x.id == this.prescription.patientID);
    // debugger;
    if (user && this.prescription.userSpecificNote != this.oldUserSpecificNote) {
      this.userService.addUpdateNote(user.id, this.prescription.userSpecificNote).subscribe(x => {
        // // // console.log(x);
      });
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // // // console.log(this.datePipe.transform(event.value, "MMM/dd/yyyy"));
  }
}