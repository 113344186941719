import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { GlobalConstants } from '../shared/global-constants';
import { Patient, User, UserRole } from '../shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    apiUrl ='https://healthwize-market.myshopify.com/admin/api/2022-10/collections/293757321379/products.json'
    constructor(private http: HttpClient) { }
    public SIGNIN_API = environment.baseUrl + '/pharmacytoken';
    public PHARMACY_USERR_API = environment.baseUrl + '/pharmacyuser';
    logInSuccess = new BehaviorSubject<User>(GlobalConstants.getcurrentUser()!);
    signin(user: any, vehicleId): Observable<any> {
        let result: Observable<any>;
        if (vehicleId) {
            result = this.http.post(this.SIGNIN_API + "?vehicleId=" + vehicleId, user);
        }
        else {
            console.log("signApi=",this.SIGNIN_API,user)
            result = this.http.post(this.SIGNIN_API, user,user.id);
        }
        return result;
    }

      getAllgreenicheProducts() {

        let headers = new HttpHeaders();

        headers = headers.set('Access-Control-Allow-Origin', '*')

        return this.http.get('https://wiki.catoinst1.sterilwize.com/products',{ headers: headers })

    }
    getUsersByPharmacy(id: string, search: string, pageIndex: number, pageSize: number): Observable<any> {
        let result: Observable<any>;
        result = this.http.get(environment.baseUrl + "/tduser/search/pagination/" + id + "?search=" + search + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize);
        return result;
    }

    getPatientByPharmacy(p1:Array<any>,search: string, pageIndex: number, pageSize: number): Observable<any> {
        let result: Observable<any>;
        // pharmacies=602a7da035c65a23da55ec50&pageIndex=0&pageSize=20&pharmacies=65d8c9bad74ee943d31b0f6c
        result = this.http.get(environment.baseUrl + "/tduser/search/pagination?pharmacies=" + p1 + "&search=" + search + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize);
        return result;
    }

    addNewPatient(data: any): Observable<any> {
        data.createdByRole = UserRole.ADMIN;
        console.log("patient insert Data=", data)
        let result: Observable<any>;
        result = this.http.post(environment.baseUrl + "/tduserwofile", data);
        return result;
    }
    updatePatient(data: any): Observable<any> {
        data.createdByRole = UserRole.ADMIN;
        let result: Observable<any>;
        const formData = new FormData();
        formData.append('user', JSON.stringify(data));
        result = this.http.put(environment.baseUrl + "/tduser", formData);
        return result;
    }
    addNewUser(data: any): Observable<any> {
        let result: Observable<any>;
        result = this.http.post(this.PHARMACY_USERR_API, data);
        return result;
    }
    updateUser(data: any): Observable<any> {
        let result: Observable<any>;
        result = this.http.put(this.PHARMACY_USERR_API, data);
        return result;
    }
    getUserAll(page: number = 0, size: number = 5): Observable<any> {
        let result: Observable<any>;
        result = this.http.get(`${this.PHARMACY_USERR_API}?page=${page}&size=${size}&ownerId=${GlobalConstants.getcurrentUser().id}`);
        return result;
    }
    updatePassword(data: any): Observable<any> {
        let result: Observable<any>;
        result = this.http.put(`${this.PHARMACY_USERR_API}/${GlobalConstants.getcurrentUser().id}/password`, data);
        return result;
    }
    getUserByEmail(email: string): Observable<User> {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/tduserByEmail/${email}`);
        return result;
    }
    searchUser(query: string): Observable<User[]> {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/tduser/search?search=${query}`);
        return result;
    }
    searchPharmacyUser(query: string): Observable<User[]> {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/pharmacyuser?page=1&size=100&search=${query}`);
        return result;
    }
    getUserById(id: string): Observable<User> {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/pharmacyuser/${id}`);
        console.log("Users by Id", result);
        return result;
    }

    updateFCMToken(id: string, deviceId: string, token: string): Observable<User> {
        let result: Observable<any>;
        result = this.http.post(`${environment.baseUrl}/pharmacyuser/${id}/fcm`, { deviceId: deviceId, token: token });
        return result;
    }
    doLogout(id: string, deviceId: string): Observable<User> {
        let result: Observable<any>;
        result = this.http.post(`${environment.baseUrl}/pharmacyuser/${id}/logout?deviceId=${deviceId}`, null);
        return result;
    }
    getUserAddresses(id: any) {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/tduser/${id}/Address`);

        return result;
    }
    deleteUserAddresses(id: any, index:number) {
        let result: Observable<any>;
        result = this.http.delete(`${environment.baseUrl}/tduser/${id}/Address?index=${index}`);

        return result;
    }
    addUpdateNote(id: any,note:String) {
        var data = { userId: id, note: note };
        let result: Observable<any>;
        result = this.http.post(`${environment.baseUrl}/tduser/note`,data);

        return result;
    }
    getPatientById(id: any) {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/tduser/${id}`);
        console.log("result patient=", result);
        return result;
    }

    getPatientFactor(algorithm_name) {
        // algorithm_name=allergic_rhinitis minorElementPrecription
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/api/v1/patientFactors?algorithm_name=${algorithm_name}`);

        return result;
    }

    getsymptoms_factor(algorithm_name){
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/api/v1/symptoms-factors?algorithm_name=${algorithm_name}`);

        return result;
    }

    getredFlagFactor(algorithm_name) {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/api/v1/redflagSymptoms?algorithm_name=${algorithm_name}`);

        return result;
    }

    getAllPrescription(algorithm_name) {
        console.log(algorithm_name)
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/api/v1/minorElementPrecription?algorithm_name=${algorithm_name}`);
        return result;
    }
    getMinorAilments() {
                let result: Observable<any>;
                result = this.http.get(`${environment.baseUrl}/api/v1/algorithms/`);
        
                return result;
                // http://localhost:5000/api/v1/minorAilmentAssesment?appointment_id=1 
            }

 getAssesmentStatus(appointment_id) {
                console.log(appointment_id)
                let result: Observable<any>;
                result = this.http.get(`${environment.baseUrl}/api/v1/minorAilmentAssesment?appointment_id=${appointment_id}`);
                return result;
}


insertAssesmentDocument(insertdata) {
    // algorithm_name=allergic_rhinitis minorElementPrecription
    var value = JSON.stringify(insertdata)
    console.log("value",insertdata)
    let result: Observable<any>;
   
    result = this.http.post(`${environment.baseUrl}/api/v1/addMinorAilmentAssesment/`,insertdata );
    //  console.log(this.http.g)
    return result;
}

updateAssesmentstatus(assesmentId,currentAppointment_id,currentstatus) {
    console.log("currentstatus=",currentstatus,'assesmentId=',assesmentId,currentAppointment_id)
    let result: Observable<any>;
   
    result = this.http.post(`${environment.baseUrl}/api/v1/addMinorAilmentAssesment/`,{ id:assesmentId,appointment_id:currentAppointment_id, assessment_status:currentstatus } );
    return result;
}


getAllminorAilmentsAssesment(){

    let result: Observable<any>;
    result = this.http.get(`${environment.baseUrl}/api/v1/allAssesment/`);
    return result;

}
    enbaleTwoFa(username: string): Observable<any>  {
        let result: Observable<any>;
        result = this.http.get(`${environment.baseUrl}/pharmacyuser/enable2Fa/${username}`);
        return result;
    }

    validateTwoFa(username: string,code:string): Observable<any> {
        let result: Observable<any>;
        result = this.http.post(`${environment.baseUrl}/pharmacyuser/validate/key`, {
            username:username,code:code
        });
        return result;
    }

    saveGoogleAuthkey(gAuth: any): Observable<any> {
        let result: Observable<any>;
        result = this.http.post(`${environment.baseUrl}/pharmacyuser/save/2fa`,gAuth);
        return result;
    }
    forgotPassword(username: string): Observable<any> {
        let result: Observable<any>;
        result = this.http.post(`${environment.baseUrl}/pharmacyuser/forgot/password?username=${username}`,null);
        return result;
    }
}