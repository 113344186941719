import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatRadioChange, MatSnackBar, MAT_DIALOG_DATA, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material';
import { UserService } from 'src/app/service/user.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { User } from 'src/app/shared/models/user.model';
import { DeSanitizeMobileNumber } from 'src/app/shared/pipes/DeSanitizeMobileNumber';
declare var $: any;
@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class CreatePatientComponent implements OnInit {

  constructor(private datePipe: DatePipe, private userService: UserService, private fb: FormBuilder, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<CreatePatientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private deSanitizeMobileNumber: DeSanitizeMobileNumber) { }
  patientForm: FormGroup;

  isUserFindedByEmail = false;
  addNewOnly = false;
  patient = null;
  ngOnInit() {
    if (this.data && this.data.addNewOnly) {
      this.isUserFindedByEmail = true;
      this.findedUser = null;
      this.addNewOnly = true;
    }

    this.patientForm = this.fb.group({
      search: [this.addNewOnly ? "dump1223" : "", [Validators.required, Validators.minLength(3)]],
      email: ["", [Validators.email]],
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      password: [this.datePipe.transform(new Date(), "MMMddyyyy"), [Validators.required, Validators.minLength(8)]],
      dateOfBirth: [""],
      sex: [0, [Validators.required]],
      phoneNumber: ["", [Validators.required, Validators.minLength(16), Validators.minLength(17)]],
      yeno: [""],
      allowSMSNotification: [true],
      allowEmailNotification: [true],
      // fullAddress: ["", [Validators.required]],
      // healthCardNumber: ["", [Validators.required]],
      // insProviderName: ["", [Validators.required]],
      // primaryCardholder: ["", [Validators.required]],
      // issueNumber: ["", [Validators.required]],
      // groupNumber: ["", [Validators.required]],
      // memberId: ["", [Validators.required]],
      // listAllergies: ["", [Validators.required]],
      // medicalProcedure: ["", [Validators.required]],
      // currentMedication: ["", [Validators.required]],
      // medicalDoctor: [""] // Checkbox initialized with false
    });

    if (this.data && this.data.edit && this.data.data) {
      this.patient = this.data.data;
      this.isUserFindedByEmail = true;
      this.findedUser = null;
      console.log(this.patient);
      this.patientForm.patchValue({
        search: "dump1223",
        email: this.patient.email,
        firstName: this.patient.firstName,
        lastName: this.patient.lastName,
        password: this.datePipe.transform(new Date(), "MMMddyyyyhhmmssSS"),
        dateOfBirth: this.transformDob(this.patient.dateOfBirth),
        sex: this.patient.sex,
        phoneNumber: this.deSanitizeMobileNumber.transform(this.patient.phoneNumber, true),
        yeno: [""],
        allowSMSNotification: [this.patient.allowSMSNotification],
        allowEmailNotification: [this.patient.allowEmailNotification],
        // fullAddress: this.patient.fullAddress,
        // healthCardNumber: this.patient.healthCardNumber,
        // insProviderName: this.patient.insProviderName,
        // primaryCardholder: this.patient.primaryCardholder,
        // issueNumber: this.patient.issueNumber,
        // groupNumber: this.patient.groupNumber,
        // memberId: this.patient.memberId,
        // listAllergies: this.patient.listAllergies,
        // medicalProcedure: this.patient.medicalProcedure,
        // currentMedication: this.patient.currentMedication,
        // medicalDoctor: this.patient.medicalDoctor
      });
    }
  }
  transformDob(dateOfBirth: string): any {
    if (dateOfBirth) {
      var ss = dateOfBirth.split("/");
      var date = new Date(parseInt(ss[2]), parseInt(ss[0])-1, parseInt(ss[1]));
      return date;
    }
    else {
      return "";
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  SaveData() {
    console.log("patientForm",this.patientForm,this.patientForm.value)
    if (this.patientForm.valid) {
      var data = this.patientForm.value;
      if (data.dateOfBirth) {
        var minDate = new Date(1900, 0, 1);
        var maxDate = new Date();
        if (data.dateOfBirth < minDate || data.dateOfBirth >= maxDate) {
          this._snackBar.open("Please enter a valid date, min. date JAN/01/1900", "Error", {
            duration: 4000,
          });
          return;
        }
      }
      data.preferredPharmacy = GlobalConstants.selectedPharmacyID;
      delete data["yeno"];
      delete data["search"];
      delete data["password"];
      data.dateOfBirth = this.datePipe.transform(data.dateOfBirth, "MM/dd/yyyy");
      data.phoneNumber = this.deSanitizeMobileNumber.transform(data.phoneNumber);

      if (this.patient == null) {
        this.userService.addNewPatient(this.patientForm.value).subscribe(x => {
          if (x && x.data) {
            this.dialogRef.close(x.data);
          }
        }, error => {
          this._snackBar.open("User with given email or phone number already exists in the system.", "Error", {
            duration: 4000,
          });
        });
      }
      else {
        data.id = this.patient.id;
        this.userService.updatePatient(data).subscribe(x => {
          this._snackBar.open("User updated successfully", "Info", {
            duration: 4000,
          });
          this.dialogRef.close(data);
        }, error => {
          this._snackBar.open("Something went wrong try again", "Error", {
            duration: 4000,
          });
        });
      }
    }
  }

  findedUser: User[] = null;
  noUserFound = false;
  FindUserByEmail() {
    this.findedUser = null;
    this.noUserFound = false;
    if (this.patientForm.controls["search"].valid) {
      this.userService.searchUser(this.patientForm.controls["search"].value).subscribe(x => {
        console.log(x);

        if (x && x.length > 0) {
          this.findedUser = x;
          this.isUserFindedByEmail = true;

        }
        else {
          this.noUserFound = true;
        }
      }, error => { });
    }
  }
  yeNoChange(event: MatRadioChange) {
    this.noUserFound = false;
    if (event.value == "2") {
      this.findedUser = null;
      this.isUserFindedByEmail = false;
      this.patientForm.controls["email"].setValue("");
      this.patientForm.controls["yeno"].setValue("");
    }
    else if (event.value == "3") {
      this.isUserFindedByEmail = true;
      this.findedUser = null;
      this.patientForm.controls["email"].setValue("");
    }
    else if (this.findedUser && this.findedUser.length > 0 && event.value) {
      var user = this.findedUser.find(x => x.id == event.value);
      this.dialogRef.close(user);
    }
  }
  // async onPaste(event: ClipboardEvent) {
  //   let clipboardData = event.clipboardData;
  //   let pastedText = clipboardData.getData("text");
  //   console.log("Pasted: ", pastedText);
  //   let fm = pastedText.split('');
  //   var ele = $(event.target);
  //   setTimeout(()=>{
  //     $(ele).val(pastedText).trigger('change');
  //   },200);
  //   // var int_tem = setInterval(() => {
  //   //   if (fm.length > 0) {
  //   //     var to_send = fm[0];
  //   //     fm.splice(0, 1);
  //   //     console.log(to_send);
  //   //     console.log(to_send.charCodeAt(0));
  //   //     $(ele).focus().trigger({ type : 'keypress', which : to_send.charCodeAt(0) });
  //   //   }
  //   //   else {
  //   //     clearInterval(int_tem);
  //   //   }
  //   // }, 1000);

  //   event.preventDefault();
  // }

}
