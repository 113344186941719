import { DOCUMENT } from '@angular/common';
import { HostListener, Inject } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { isString } from 'util';
import { ImagePreviewListComponent } from '../image-preview-list/image-preview-list.component';
import { PrescriptionService } from '../service/prescription.service';
import { UserService } from '../service/user.service';
import { GlobalConstants } from '../shared/global-constants';
import { Pharmacy } from '../shared/models/pharmacy.model';
import { Prescription } from '../shared/models/prescription.model';
import { LocalDateTimePipe } from '../shared/pipes/local-date-time.pipe';
import { PrescriptionStatuses, PrescriptionType } from '../shared/pipes/prescription-status.pipe';
import { ConfirmationDialogComponent } from '../shared/view/confirmation-dialog/confirmation-dialog.component';
declare var $: any;
declare var Compress: any;
@Component({
  selector: 'app-ready-to-pickup',
  templateUrl: './ready-to-pickup.component.html',
  styleUrls: ['./ready-to-pickup.component.css'],

})
export class ReadyToPickupComponent implements OnInit {
  prescriptionStatusEnum = PrescriptionStatuses;
  countByPharmacy: any[];
  constructor(private prescription: PrescriptionService, private dialog: MatDialog, private _snackBar: MatSnackBar, private fb: FormBuilder, private userService: UserService, private localDate: LocalDateTimePipe, @Inject(DOCUMENT) private doc: Document) { }
  listPrescription: Prescription[] = null;
  unSeenPrescription: Prescription[] = null;
  pharmacieList: Pharmacy[] = null;
  selectedPharmacy: Pharmacy;
  lastLoginDate: Date = null;
  ngOnInit() {

    this.pharmacieList = localStorage.getItem("pharmacieList") ? (JSON.parse(localStorage.getItem("pharmacieList")) as [Pharmacy]) : null;
    this.selectedPharmacy = localStorage.getItem("pharmacieList") ? (JSON.parse(localStorage.getItem("pharmacieList")) as [Pharmacy]).find(x => x.id == GlobalConstants.selectedPharmacyID) : null;
    this.lastLoginDate = new Date(+localStorage.getItem(GlobalConstants.CURRENT_LOCAL_LOGIN_TIME))
    this.prescription.getAllWhichAreReadyToPickup().subscribe(x => {
      this.listPrescription = x.data;

      // this.listPrescription = this.listPrescription.filter(p => (p.status == PrescriptionStatuses.READY_TO_DELIVER || p.status == PrescriptionStatuses.OUT_FOR_DELIVERY));
      this.listPrescription.forEach(element => {
        element.showOnList = +localStorage.getItem(GlobalConstants.CURRENT_LOCAL_LOGIN_TIME) > new Date(element.updateDateAndTime).getTime();
        if (this.pharmacieList) {
          element.pharmacyName = this.pharmacieList.find(ph => ph.id == element.pharmacyID).name;
        }
        var dt = this.localDate.transform(element.prefferdDeliveryDate);
        dt.setHours(0, 0, 0);

        if (!element.prefferdDeliveryTime || element.prescriptionType == PrescriptionType.EXPRESS_DELIVERY) {
          element.prefferdDeliveryTime = "Express";
        }
        else {
          var time = element.prefferdDeliveryTime.split(":");
          try {
            dt.setHours(parseInt(time[0]), parseInt(time[1]), parseInt(time[2]));
          } catch (e) { }
        }
        element.prefferdDeliveryDate = dt;
        element.deliveryCodeOld = element.deliveryCode;
        element.deliveryCode = "";
        if (element.deliveryAddress) {
          var formatedA = [];
          if (element.deliveryAddress.unitNo) {
            formatedA.push(element.deliveryAddress.unitNo);
          }
          if (element.deliveryAddress.buildingName) {
            formatedA.push(element.deliveryAddress.buildingName);
          }
          formatedA.push(element.deliveryAddress.streetAddress + "," + element.deliveryAddress.city + " " + element.deliveryAddress.postalCode);
          element.deliveryAddress.streetAddress = formatedA.join(", ");
          element.deliveryAddress.city = this.getGoogleMapLink(element.deliveryAddress.streetAddress);
          element.deliveryAddress.streetAddress = element.deliveryAddress.firstName + " " + element.deliveryAddress.lastName + ",<br/>" + element.deliveryAddress.streetAddress;
        }

      });
      this.unSeenPrescription = this.listPrescription.filter(x => x.showOnList == false);

      if (this.unSeenPrescription && this.unSeenPrescription.length > 0) {
        this.listPrescription = this.listPrescription.filter(x => x.showOnList == true);
      }
      this.currentFillter = localStorage.getItem("driverFillter") ? localStorage.getItem("driverFillter") : "";
      this.groupByPharmacy = localStorage.getItem("driverGroupByPharmacy") && localStorage.getItem("driverGroupByPharmacy") == "true";
      if (this.currentFillter) {
        this.applyFilter();
      } else {
        this.sortAndLineItem();
      }
      this.countByPharmacy = [];
      this.pharmacieList.forEach(ph => {
        var cnt = this.listPrescription.filter(x => x.pharmacyID == ph.id).length;
        this.countByPharmacy.push({
          name: ph.name,
          count: cnt
        })
      });

    }, err => {
      console.log(err);
    });
    this.codeForm = this.fb.group({
      customerCode: ['', [Validators.required, Validators.minLength(4)]],
      deliveredTo:[''],
      otpOption: [true, [Validators.required]],
    });
  }
  orgList: Prescription[] = null;
  sortAndLineItem() {
    var isDescending = false;
    // this.listPrescription = this.listPrescription.filter(x => x.pharmacyID == GlobalConstants.selectedPharmacyID).sort((a, b) => {
    //   return isDescending ? b.prefferdDeliveryDate.getTime() - a.prefferdDeliveryDate.getTime() : a.prefferdDeliveryDate.getTime() - b.prefferdDeliveryDate.getTime()
    // });

    if (this.orgList == null) {
      this.orgList = this.listPrescription;
    }
    else {
      this.listPrescription = this.orgList;
    }
    this.listPrescription = this.listPrescription.sort((a, b) => {

      return isDescending ? b.prefferdDeliveryDate.getTime() - a.prefferdDeliveryDate.getTime() : a.prefferdDeliveryDate.getTime() - b.prefferdDeliveryDate.getTime()
    });
  }
  getGoogleMapLink(text: string): string {
    var q = $.trim(text).replace(/<br\s*\/?>/ig, '').replace(/\r?\n/, ',').replace(/\s+/g, ' ');
    var link = 'http://maps.google.com/maps?q=' + encodeURIComponent(q);

    return link;
  }
  outForDelivery(_Prescription: Prescription): void {
    this.prescription.outForDelivery(_Prescription.id).subscribe(result => {
      this._snackBar.open("Order Marked for out for Deliver", "OK", {
        duration: 2000,
      });
      _Prescription.status = PrescriptionStatuses.OUT_FOR_DELIVERY;

    }, error => {
      console.error(error);

    });
  }

  Delivered(_Prescription: Prescription): void {
    this.showCodeForm(_Prescription, false);
  }
  DeliveredFail(_Prescription: Prescription): void {

    this.showCodeForm(_Prescription, true);
  }

  ViewImages(id) {
    $("#img_" + id).addClass('showImgListForTemp');
    setTimeout(() => {
      $("#img_" + id).find('img').first().click();
    }, 100);
  }
  codeForm: FormGroup;
  deliverFaildForm: FormGroup;
  currentPresciption: Prescription = null;
  isDeliverFail = false;
  showCodeForm(_p: Prescription, isDeliverFail: boolean) {
    this.files = null;
    if (!isDeliverFail) {
      this.codeForm = this.fb.group({
        customerCode: [_p.deliveryCode, [Validators.required, Validators.minLength(4)]],
        deliveredTo: [_p.deliveredTo],
        otpOption: [false, [Validators.required]],
      });
    }
    else if (isDeliverFail) {
      this.deliverFaildForm = this.fb.group({
        failureReasone: ['', [Validators.required]],
        failureReasonOther: ['', [Validators.required, Validators.minLength(4)]],
      });
    }
    this.isDeliverFail = isDeliverFail;
    this.currentPresciption = _p;
    if (!isDeliverFail && !this.currentPresciption.trackWithOtp) {
      $(".enterCustomerCode").addClass('enterCustomerCodeShow');
    }
    else {
      $(".enterCustomerCode").addClass('enterCustomerCodeShow');
    }
  }
  onSubmitCode() {
    //let deliveryCode= $(".deliveryCode").value;

    var notWithOtp = false;
    var msg = "Collector does not have access to OTP";
    if (this.currentPresciption.trackWithOtp) {
      notWithOtp = this.codeForm.controls["otpOption"].value
      if (this.codeForm.invalid && !notWithOtp) {
        return;
      }
    }
    else {
      notWithOtp = true;
      msg = "Track without OTP.";
    }
    var customerDeliveryCode = !notWithOtp ? this.codeForm.value.customerCode : "";
   
    this.CloseModal("enterCustomerCodeShow");

    if (this.currentPresciption.requiredCashCollection > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          isRadio: false,
          message: 'Did you collect the cash.?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe((cashCollected: boolean) => {

        if (cashCollected != undefined) {
          if (cashCollected) {
            this.markAsDelivered(this.currentPresciption.id, customerDeliveryCode, notWithOtp ? msg : "", true, this.codeForm.value.deliveredTo);
          }
          else {
            this._snackBar.open("Please collect the cash from customer.", "OK", {
              duration: 2000,
            });
          }
        }
        else {
          this._snackBar.open("Action cancelled", "OK", {
            duration: 2000,
          });
        }
      });



    }
    else {
      this.markAsDelivered(this.currentPresciption.id, customerDeliveryCode, notWithOtp ? msg : "", false, this.codeForm.value.deliveredTo);
    }
  }
  markAsDelivered(prescriptionID: string, customerDeliveryCode: string, noOtpReason: string, cashCollected: boolean, deliveredTo:String) {

    this.prescription.delivered(prescriptionID, customerDeliveryCode, noOtpReason, cashCollected, this.files, deliveredTo).subscribe(result => {
      this._snackBar.open("Order Marked as Delivered", "OK", {
        duration: 2000,
      });
      this.currentPresciption.status = PrescriptionStatuses.DELIVERED;
      this.files = null;
    }, error => {
      console.error(error);
      this._snackBar.open("Invalid deliver code", "error", {
        duration: 2000,
      });
    });
  }
  onSubmitFailure() {
    var reason = this.deliverFaildForm.controls["failureReasone"].valid && this.deliverFaildForm.controls["failureReasone"].value != "Other" ? this.deliverFaildForm.controls["failureReasone"].value : this.deliverFaildForm.controls["failureReasonOther"].valid ? this.deliverFaildForm.controls["failureReasonOther"].value : "";
    if (reason) {
      this.CloseModal("enterCustomerCodeShow");
      this.prescription.deliveryFailed(this.currentPresciption.id, reason, this.files).subscribe(result => {
        this._snackBar.open("Order Marked as Delivery fail", "OK", {
          duration: 2000,
        });
        this.currentPresciption.status = PrescriptionStatuses.DELIVERY_FAILED;
        this.files = null;
      }, error => {
        console.error(error);

      });
    }
  }
  CloseModal(modalClass) {
    $('.' + modalClass).toggleClass(modalClass);
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    $('.delTime').each((i, obj) => {
      let num = $(window).scrollTop();
      var tmp = $(obj).attr("d-top") ? parseInt($(obj).attr("d-top")) : -1;
      if (num >= $(obj).offset().top && tmp == -1) {
        $(obj).attr("d-top", $(obj).offset().top);
        $(obj).addClass('stickTop');
      }
      else if (tmp != -1 && num < tmp) {
        $(obj).attr("d-top", -1);
        $(obj).removeClass('stickTop');
      }
    });

  }
  showFillter = false;
  currentFillter = "";
  groupByPharmacy = false;
  showFilterPopup() {
    this.showFillter = !this.showFillter;
  }
  applyFilter() {
    //if (this.currentFillter) {

    localStorage.setItem("driverFillter", this.currentFillter);
    localStorage.setItem("driverGroupByPharmacy", this.groupByPharmacy ? "true" : "false");
    $('html, body').animate({ scrollTop: (0) }, '2000');
    var isDescending = false;
    this.sortAndLineItem();
    if (this.currentFillter.indexOf("Dsc") > -1) {
      isDescending = true;
    }
    switch (this.currentFillter) {
      case 'distanctAsc': {
        this.listPrescription = this.listPrescription.sort((a, b) => {
          return isDescending ? b.distance - a.distance : a.distance - b.distance
        });
        break;
      } case 'distanceDsc': {
        this.listPrescription = this.listPrescription.sort((a, b) => {
          return isDescending ? b.distance - a.distance : a.distance - b.distance
        });
        break;
      } case 'dateAsc': {
        this.listPrescription = this.listPrescription.sort((a, b) => {
          return isDescending ? b.prefferdDeliveryDate.getTime() - a.prefferdDeliveryDate.getTime() : a.prefferdDeliveryDate.getTime() - b.prefferdDeliveryDate.getTime()
        });
        break;
      } case 'dateDsc': {
        this.listPrescription = this.listPrescription.sort((a, b) => {
          return isDescending ? b.prefferdDeliveryDate.getTime() - a.prefferdDeliveryDate.getTime() : a.prefferdDeliveryDate.getTime() - b.prefferdDeliveryDate.getTime()
        });
        break;
      }
    }
    if (this.groupByPharmacy && this.pharmacieList) {
      var temp = JSON.parse(JSON.stringify(this.listPrescription)) as [Prescription];
      this.listPrescription = [];
      this.pharmacieList.forEach(ph => {
        this.listPrescription.push(...temp.filter(x => x.pharmacyID == ph.id));
      });
    }
    this.showFillter = false;
    //  }
  }
  clearFilter() {
    $('html, body').animate({ scrollTop: (0) }, '2000');
    this.showFillter = false;
    this.currentFillter = "";
    this.groupByPharmacy = false;
    this.sortAndLineItem();
    localStorage.setItem("driverFillter", this.currentFillter);
    localStorage.setItem("driverGroupByPharmacy", "false");
  }
  ViewUnseen() {
    $('html, body').animate({ scrollTop: (200) }, '200');
    setTimeout(() => {

      var temp = [];
      this.listPrescription.forEach(element => {
        temp.push(element);
      });
      this.listPrescription = [];
      this.listPrescription.push(...this.unSeenPrescription);
      this.listPrescription.push(...temp);
      this.orgList = null;
      this.unSeenPrescription = null;
      // this.currentFillter = "dateDsc";
      // this.applyFilter();
    }, 300);
  }

  openCamera() {
    $("#file-input").click();
  }
  files = null;
  compress = new Compress();




  onFileSelected(event: any) {
    this.files = [];
    // console.log("files =>>>>>>>>>>>>> "+event.target.files)
    this.files = event.target.files;
    
    const extension = this.files.name.split('.').pop();// Get the file extension
    // console.log(">>>>>>>>>>>>>>>>>>>> "+extension)
    const fileName = `file_${new Date().getTime()}.${extension}`; // Generate new file name

    if (event.target.files) {
      $('.csLoader').css({ display: 'flex' });
      const files1 = [...event.target.files];
      console.log("files1 = "+files1)
      this.compress.compress(files1, {
        size: 4,
        quality: .75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      }).then((data: any[]) => {
        console.log(data);
        var newFiles: any = [];
        data.forEach(element => {
          // newFiles.push(this.base64ToFile(element.prefix + element.data, new Date().getTime() + ".jpeg"));
          newFiles.push(this.base64ToFile(element.prefix + element.data, "."+extension));
        });
        this.files = newFiles;
        $('.csLoader').css({ display: 'none' });
      });
    }
  }

  base64ToFile(dataURI: any, fileName: any): File {

    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }
}

export interface TempObject {
  prefferdDeliveryDate: any;
  prefferdDeliveryTime: any;
  data: Prescription[]
}
