// // *********** test env ******
// export const environment = {
//   production: false,
//   baseUrl: 'https://testapi.mylocalrx.ca',
//   apiKey: "AIzaSyCpDUSLIz47hZSg_-2_NqukOwm8fHpbOw0",
//   authDomain: "mylocalrx-7066f.firebaseapp.com",
//   projectId: "mylocalrx-7066f",
//   storageBucket: "mylocalrx-7066f.appspot.com",
//   messagingSenderId: "120440912034",
//   appId: "1:120440912034:web:c6302378717b135dabbffb"
//   // measurementId: "G-FVT36285SG"
// };
// // *********** test env ******

// *********** prod env ******
// export const environment = {
//   production: false,
//   baseUrl: 'https://apitd.mylocalrx.ca',
//   apiKey: "AIzaSyCpDUSLIz47hZSg_-2_NqukOwm8fHpbOw0",
//   authDomain: "mylocalrx-7066f.firebaseapp.com",
//   projectId: "mylocalrx-7066f",
//   storageBucket: "mylocalrx-7066f.appspot.com",
//   messagingSenderId: "120440912034",
//   appId: "1:120440912034:web:c6302378717b135dabbffb"
//   // measurementId: "G-FVT36285SG"
// };
// *********** prod env ******

//providers cred

export const environment = {
  production: true,
  // baseUrl: 'https://backend.catoinst2.healthwize.market',
  // baseUrl: 'https://wize-health.apps.sterilwize.com',
  baseUrl:'https://backend.apps.wize.health',
  apiKey: "AIzaSyBQMttAryN7SxXHe_gTQZUvPpYgz2o5gmw",
  authDomain: "providers-92c5e.firebaseapp.com",
  projectId: "providers-92c5e",
  storageBucket: "providers-92c5e.appspot.com",
  messagingSenderId: "897796971550",
  appId: "1:897796971550:web:9ebdcb921a652f133d620f",
};
